import { defineStore } from "pinia";

export const useProjectStore = defineStore('project',{
    state:()=>{
        return {
            projects:[],
            associates:[],
            all_associates:[],
            currentProject:{},
            draftProject:{}
        }
    },
    actions:{
        setProjects(data){
            this.projects = data
        },
        setProject(data){
            this.currentProject = data
        },
        addProject(data){
            this.projects.unshift(data)
        },
        deleteProject(id){
           const projectIndex = this.projects.findIndex(proj=>proj.id === id);
           this.projects.splice(projectIndex,1)
        },
        updateProject(id,data){
            // this.setProject(data)
            const projectIndex = this.projects.findIndex(proj=>proj.id === id);
            this.projects[projectIndex]=data
        },
        setAssignedAssociates(data){
            this.associates = data
        },
        setAllAssociates(data){
            this.all_associates = data
        },
        getVaNAme(id){
            const projectIndex = this.associates.findIndex(a=>a.id === id);
            if(projectIndex == -1) return ""
            let data = this.associates[projectIndex]
            return `${data.first_name} ${data.last_name}`
        },
        resetProjectStore(){
            this.projects = []
            this.associates=[]
            this.all_associates=[]
            this.currentProject={}
            this.draftProject={}        
        }
    },
    getters:{
        getProjects:(state)=>{
            return state.projects
        },
        getProject:(state)=>{
            return state.currentProject
        }
    }
})